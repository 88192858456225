export default () => ({
    fields: {
        energy: 200,
    },
    status: {
        energy: { valid: null, error: '' },
    },
    rules: {
        energy: ['required', 'numeric', 'min:180', 'max:800'],
    },

    init() {
        window.addEventListener('hashchange', (e) => {
            const energyStr = localStorage.getItem("mealEnergy")
            this.fields.energy = energyStr === null ? 280 : Number(energyStr)
            // console.log("Change Energy", this.fields.energy)
        })
    },
    fieldStatus(fieldId) {
        if (this.status[fieldId].valid === null) { return '' }
        return this.status[fieldId].valid ? '' : 'is-danger';
    },

    fieldMessages(fieldId) {
        return this.status[fieldId].error;
    },

    validate(fieldId) {
        this.status[fieldId] = Iodine.assert(this.fields[fieldId], this.rules[fieldId])
    },

    submit() {
        const result = Iodine.assert(this.fields, this.rules)
        this.status = result.fields
        // console.log('Validation result mealEnergy', result, this.fields.energy)
        if (result.valid) {
            localStorage.setItem("mealEnergy", this.fields.energy)
            Alpine.$router.push("/")
        }
    }
})