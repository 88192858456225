import postData from './postData'
import { api_server } from "./data/config.json" assert {type: 'json'}


export default () => ({
    fields: {
        recovery_email: '',
    },
    status: {
        recovery_email: { valid: null, error: '' },
    },
    rules: {
        recovery_email: ['required', 'email'],
    },

    fieldStatus(fieldId) {
        if (this.status[fieldId].valid === null) { return '' }
        return this.status[fieldId].valid ? '' : 'is-danger';
    },

    fieldMessages(fieldId) {
        return this.status[fieldId].error;
    },

    validate(fieldId) {
        this.status[fieldId] = Iodine.assert(this.fields[fieldId], this.rules[fieldId])
    },

    submit() {
        const status = Iodine.assert(this.fields.recovery_email, this.rules.recovery_email)
        if (status.valid) {
            postData(`${api_server}/req-reset`, { user: this.fields.recovery_email }).then((data) => {
                console.log("fetch data", data)
                console.log("requestsResetCode for: ", this.fields.recovery_email, status)
                if (data !== null && data.ok) {
                    Alpine.store('recovery_email', this.fields.recovery_email)
                    console.log("Storing email in store: ", Alpine.store('recovery_email'))
                }
                Alpine.$router.push("/reset-password")
            })
        }
    }
})