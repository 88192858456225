import { buildNutritionCache, previous_next_recipe, getRecipe, calcNutrition } from './recipes'


export default () => ({
    energy: 0,
    recipe:{
        _id: "r01001",
        category: "1",
        date: 1678580851807,
        name: "SMOKED SALMON TOMATO SALAD",
        short_description: "short description",
        nutrition: {
         alcohol: 0,
         carbs: 30,
         density: 1,
         fat: 40,
         protein: 30
        },
        ingredients: []
       },

    nutritionCache: [],
    neighbors: {
        previous: "", 
        next: ""
    },

    init() {
        window.addEventListener('hashchange', (e) => {
            const energyStr = localStorage.getItem("mealEnergy")
            this.energy = energyStr === null ? 280 : Number(energyStr)
            this.recipe = getRecipe(this.$router.params.id)
            if (typeof(this.recipe) !== 'undefined') {
                this.nutritionCache = buildNutritionCache(this.$router.params.id, this.energy)
                this.recipe.nutrition = calcNutrition(this.nutritionCache)
                this.neighbors = previous_next_recipe(this.$router.params.id)    
            }
        })
    },
    id2href(recipeId) {
        if (recipeId == "") {
            return "#/"
        }
        return `#/recipe/${recipeId}`
    },
})