import login from './login'
import reset_request from './reset_request'
import check_code from './check_code'
import reset_password from './reset_password'
import recipe_grid from './recipe_grid'
import x_recipe from './x_recipe'
import change_energy from './change_energy'


document.addEventListener("login", (e) => {
    console.log("login event global", e.detail)
})

document.addEventListener('alpine:init', () => {
    Alpine.data("login", login)
    Alpine.data("change_energy", change_energy)
    Alpine.data("reset_request", reset_request)
    Alpine.data("check_code", check_code)
    Alpine.data("reset_password", reset_password)
    Alpine.data("recipe_grid", recipe_grid)
    Alpine.data("x_recipe", x_recipe)
    if (localStorage.getItem("mealEnergy") === null) {
        localStorage.setItem("mealEnergy", 280)
    }
})


