import postData from './postData'
import { api_server } from "./data/config.json" assert {type: 'json'}


export default () => ({
    show1: false,
    show2: false,
    fields: {
        password1: '',
        password2: '',
    },
    status: {
        password1: { valid: null, error: '' },
        password2: { valid: null, error: '' },
    },
    rules: {
        password1: ['required', 'string', 'minLength:8'],
        password2: ['required', 'string', 'minLength:8'],
    },

    fieldStatus(fieldId) {
        if (this.status[fieldId].valid === null) { return '' }
        return this.status[fieldId].valid ? '' : 'is-danger';
    },

    fieldMessages(fieldId) {
        return this.status[fieldId].error;
    },

    validate(fieldId) {
        this.status[fieldId] = Iodine.assert(this.fields[fieldId], this.rules[fieldId])
        if (fieldId == "password2" && this.status["password2"].valid) {
            this.status["password2"].valid = this.fields.password1 == this.fields.password2
            this.status["password2"].error = this.status["password2"].valid ? '' : 'Passwords do not match!'
        }
    },

    submit() {
        const result = Iodine.assert(this.fields, this.rules)
        if (this.fields.password1 !== this.fields.password2) {
            result.valid = false
            result.fields.password2 = { valid: false, error: "Passwords do not match!" }
        }
        this.status = result.fields
        console.log('Validation result', result)
        console.log("New password for: ", Alpine.store('recovery_email'), this.fields.password1, Alpine.store('recovery_code'))
        if (result.valid) {
            postData(`${api_server}/new-password`, { user: Alpine.store('recovery_email'), password: this.fields.password1, code: Alpine.store('recovery_code') }).then((data) => {
                console.log("fetch data", data)
                Alpine.$router.push("/login")
            });
        }
    }
})