import { genRecipesGrid } from './recipes'


const IDlinkRenderer = function (params) {
    const href = `#/recipe/${params.value}`
    return "<a href="+href+">"+params.value+"</a>"
}

const NamelinkRenderer = function (params) {
    const href = `#/recipe/${params.data.ID}`
    return "<a href="+href+">"+params.value+"</a>"
}

export default () => ({
    query: '',

    gridOptions: {
        columnDefs: [
            {
                field: "ID",
                hide: screen.width < 400,
                width: 90,
                cellRenderer: IDlinkRenderer,
            },
            {
                field: "Category",
                headerName: "CAT",
                hide: screen.width < 400,
                width: 90
            },
            {
                field: "Name",
                width: 400,
                cellRenderer: NamelinkRenderer,
            },

            {
                field: "Ingredients",
                hide: screen.width < 1000,
                width: 800,
            }
        ],
        defaultColDef: {
            editable: false,
            filter: 'agTextColumnFilter',
            sortable: true,
        },

        cacheQuickFilter: true,
        rowData: genRecipesGrid(),
    },
    init() {
        let recipes_grid = new agGrid.Grid(document.querySelector('#recipesGrid'), this.gridOptions);
        this.gridOptions.api.setQuickFilter('');
    },

    clear(focusAfter) {
        this.gridOptions.api.setQuickFilter('')
        this.query = ''
    },
    
    update(value) {
        this.gridOptions.api.setQuickFilter(value)
    }
})

