
import jwtDecode from "./jwt-decode/index"
import postData from './postData'
import { api_server } from "./data/config.json" assert {type: 'json'}


export default () => ({
    loggedIn_status: false,
    tokenClaims: null,
    show: false,
    fields: {
        email: '',
        password: '',
        persistent: false,
    },
    status: {
        email: { valid: null, error: '' },
        password: { valid: null, error: '' },
        persistent: { valid: null, error: '' },
    },
    rules: {
        email: ['required', 'email'],
        password: ['required', 'string', 'minLength:8'],
        persistent: ['required', 'boolean'],
    },
    init() {
        this.$router.config({ mode: 'hash' })
        const encodedToken = sessionStorage.getItem("token") || localStorage.getItem("token")
        if (typeof (encodedToken) != undefined && encodedToken != null) {
            this.tokenClaims = jwtDecode(encodedToken)
            this.loggedIn_status = true
            // console.log("we are logged in, but?, loggedIn_status", this.loggedIn_status)

            // console.log("tokenClaims", this.tokenClaims)
            Alpine.store('tokenClaims', this.tokenClaims)
            Alpine.$router.push("/", { replace: true })

            this.$watch('loggedIn_status', () => console.log("WATCHING loggedIn_status", this.loggedIn_status))

        }
    },
   
    logOut() {
        Alpine.store('tokenClaims', '')
        sessionStorage.removeItem("token")
        localStorage.removeItem("token")
        this.loggedIn_status = false
        Alpine.$router.push("/")
        console.log("logging out, but?, loggedIn_status", this.loggedIn_status)
    },
    goto (path) {
        console.log("Not going anywhere...", path)
        this.$router.push(path)
    },
    fieldStatus(fieldId) {
        if (this.status[fieldId].valid === null) { return '' }
        return this.status[fieldId].valid ? '' : 'is-danger';
    },

    fieldMessages(fieldId) {
        return this.status[fieldId].error;
    },
    validate(fieldId) {
        this.status[fieldId] = Iodine.assert(this.fields[fieldId], this.rules[fieldId])
    },
    submit() {
        const result = Iodine.assert(this.fields, this.rules)
        this.status = result.fields
        console.log('Validation result', result)
        console.log("Login with: ", this.fields.email, this.fields.password, this.fields.persistent)
        if (result.valid) {
            postData(`${api_server}/login`, { user: this.fields.email, password: this.fields.password }).then((data) => {
                console.log("fetch data", data)
                const tokenObject = data
                if (data.ok) {
                    if (this.fields.persistent) {
                        localStorage.setItem('token', tokenObject['token'])
                    } else {
                        sessionStorage.setItem('token', tokenObject['token'])
                    }
                    this.tokenClaims = jwtDecode(tokenObject['token'])
                    Alpine.store('tokenClaims', this.tokenClaims)
                    document.dispatchEvent(new CustomEvent("login", {detail: tokenObject['token']}))
                    this.loggedIn_status = true
                    console.log("we just logged in, but?, loggedIn_status", this.loggedIn_status)
                    console.log("Alpine.store tokenClaims", Alpine.store('tokenClaims'))    
                    location.reload()
                }
            });
        }
    }
})