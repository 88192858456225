import postData from './postData'
import { api_server } from "./data/config.json" assert {type: 'json'}


export default () => ({
    reset_code: '',
    code_correct: false,

    init() {
        console.log("Init check code, reset_code", this.reset_code)
        let clear_autofll = () => {
            console.log("clear_autofill", this.reset_code, Alpine.store('recovery_email'))
            if (this.reset_code.length > 6) {
                this.reset_code = ''
            }
        }

        this.$watch('reset_code', () => clear_autofll())
    },

    submit() {
        console.log("Submit check code, reset_code", this.reset_code)
        if (this.reset_code.length == 6) {
            postData(`${api_server}/check-code`, { user: Alpine.store('recovery_email'), password: this.reset_code }).then((data) => {
                console.log("fetch data", data)
                console.log("validateResetCode for: ", Alpine.store('recovery_email'), this.reset_code)
                if (data !== null && data.ok) {
                    this.code_correct = true
                    Alpine.store('recovery_code', this.reset_code)
                }
            })
        }
    },
})