import * as R from 'ramda'
import recipes_ingredients from "./data/recipes_ingredients.json" assert {type: 'json'}


/**Given a recipe id, the objects for all recipes and ingredients and the target energy
 * a nutrition cache with one object per ingredient is returned. The quantities per ingredient
 * are adjusted to match the target energy of the recipe and the density noted in recipe.nutrition.density.
 * 
 * @param {string} rid - recipe id
 * @param {number} target_energy - target energy for the recipe in kCal
 * @returns {Array} - nutritionCache, objects:
 */
const buildNutritionCache = (rid, target_energy) => {
    const getIngredient = (iid) => {
        const ingredientsDB = recipes_ingredients.ingredients
        if (ingredientsDB[iid]) {
            return R.clone(ingredientsDB[iid])
        }
        console.error(`Ingredient ${iid} not found!`)
        return { alcohol: 0.0, carbs: 0.0, density: 0, fat: 0.0, id: iid, name: "Ingredient not found!", protein: 0.0 }
    }

    const recipe = R.clone(recipes_ingredients.recipes[rid]);
    let nutritionCache = [];
    if (recipe !== undefined && recipe.ingredients !== undefined && recipe.ingredients.length > 0) {
        for (let i = 0; i < recipe.ingredients.length; i++) {
            let ingredient = getIngredient(recipe.ingredients[i].iref);
            ingredient.p = recipe.ingredients[i].quantity;
            nutritionCache.push(ingredient)
        }
    }
    for (let i=0; i<nutritionCache.length; i++) {
        if (nutritionCache[i].density > 0) {
            nutritionCache[i].quantity = Math.round(nutritionCache[i].p * target_energy / (100 * nutritionCache[i].density))
        } 
    }
   const caloric_weight = R.sum(R.map(x => x.quantity, R.filter(x => x.density > 0, nutritionCache)))
    for (let i=0; i<nutritionCache.length; i++) {
        if (nutritionCache[i].density == 0) {
            nutritionCache[i].quantity = Math.round(nutritionCache[i].p * caloric_weight / 100)
        }
    }
    return nutritionCache
}


const genRecipesGrid = () => {
    const recipes = Object.values(recipes_ingredients.recipes)
    const ingredientDb = recipes_ingredients.ingredients
    const ingredient_names = (ingredients) => R.map(x => ingredientDb[x.iref].name, ingredients)
    const category = (value) => {
        const lookup = {
            '0': '',
            '1': 'Salads',
            '2': 'Soups',
            '3': 'Creams',
            '4': 'Skillets',
            '5': 'Stirfry',
            '6': 'Airfry',
            '7': 'Shakes',
            '8': 'Slims',
            '9': 'Drinks'
        }
        return lookup[value]
    }
    const extract_cols = (r) => {
        return {ID: r._id, Name: r.name, Ingredients: ingredient_names(r.ingredients), Category: category(r.category) }
    }
    return R.map(extract_cols, recipes )
}


const previous_next_recipe = (recipe_id) => {
    const recipe_ids = Object.keys(recipes_ingredients.recipes)
    const current_index = recipe_ids.indexOf(recipe_id)
    const previous_recipe_id = current_index > 0 ? recipe_ids[current_index-1] : ""
    const next_recipe_id = current_index < (recipe_ids.length - 1) ? recipe_ids[current_index+1] : ""
    return { previous: previous_recipe_id, next: next_recipe_id }

}


/**
 * 
 * @param {Array} nutritionCache 
 * @returns {Object} - {energy, protein, fat, carbs, alcohol, density} for nutritionCache
 */
const calcNutrition = (nutritionCache) => {
    if (nutritionCache.length == 0) {
        return { energy: 0, protein: 0, fat: 0, carbs: 0, alcohol: 0, density: 0 }
    }
    const energy = Math.round(R.sum(R.map(x => x.density * x.quantity, nutritionCache)));
    if (energy == 0) {
        return { energy: 0, protein: 0, fat: 0, carbs: 0, alcohol: 0, density: 0 }
    }
    return {
        energy: energy,
        protein: Math.round(100 * R.sum(R.map(x => x.density * x.quantity * x.protein, nutritionCache)) / energy),
        fat: Math.round(100 * R.sum(R.map(x => x.density * x.quantity * x.fat, nutritionCache)) / energy),
        carbs: Math.round(100 * R.sum(R.map(x => x.density * x.quantity * x.carbs, nutritionCache)) / energy),
        alcohol: Math.round(100 * R.sum(R.map(x => x.density * x.quantity * x.alcohol, nutritionCache)) / energy),
        density: Math.round(100 * energy / R.sum(R.map(x => x.quantity, nutritionCache))) / 100
    }
}


const getRecipe = (recipe_id) => { return recipes_ingredients.recipes[recipe_id] }

export {
    buildNutritionCache,
    genRecipesGrid,
    previous_next_recipe,
    getRecipe,
    calcNutrition,
}
